import {TableColumnType, Typography, useTranslation} from '@hconnect/uikit'
import {Box} from '@mui/material'
import React from 'react'

import {prettyRoleName} from '../../../layouts/Roles/utils'
import {ExtendedRoleAssignment} from '../../../modules/ManageUsers.selectors'
import {CompanyName} from '../../../pages/ManageUser/dialogs/CompanyName'
import {OrgUnit} from '../../../pages/ManageUser/dialogs/OrgUnit'

type CloneUserListRowsProps = {
  cloneableRoleIds: number[]
}

export const CloneUserListRows = ({cloneableRoleIds}: CloneUserListRowsProps) => {
  const {t} = useTranslation()

  const rowsPool: {[key: string]: TableColumnType} = {
    role: {
      field: 'role',
      mobileRowHeader: true,
      sortable: false,
      filterable: false,
      headerName: t('manageUser.cloneDialog.role'),
      headerAlign: 'left',
      width: '200px',
      renderCell: (role: ExtendedRoleAssignment) => (
        <Typography
          style={{
            opacity: cloneableRoleIds.includes(role.id) ? 1 : 0.5,
            textAlign: 'left',
            whiteSpace: 'nowrap'
          }}
        >
          {t(`roleAssignment.roleTypes.${role.roleType}`, prettyRoleName(role.roleType))}
        </Typography>
      )
    },
    area: {
      field: 'area',
      headerName: t('manageUser.cloneDialog.area'),
      headerAlign: 'left',
      renderCell: (role: ExtendedRoleAssignment) => (
        <Box display="flex" flexDirection="column">
          <Typography
            style={{opacity: cloneableRoleIds.includes(role.id) ? 1 : 0.5, textAlign: 'left'}}
          >
            {role.dataScope['areaId'] || '-'}
          </Typography>
        </Box>
      ),
      filterShowSearch: false
    },
    country: {
      field: 'country',
      sortable: false,
      filterable: false,
      headerName: t('manageUser.cloneDialog.country'),
      headerAlign: 'left',
      renderCell: (role: ExtendedRoleAssignment) => (
        <Box display="flex" flexDirection="column">
          <Typography
            style={{opacity: cloneableRoleIds.includes(role.id) ? 1 : 0.5, textAlign: 'left'}}
          >
            {role.dataScope['countryId'] || '-'}
          </Typography>
        </Box>
      ),
      filterShowSearch: false
    },
    businessLine: {
      field: 'businessLine',
      sortable: false,
      filterable: false,
      headerName: t('manageUser.cloneDialog.businessLine'),
      headerAlign: 'left',
      renderCell: (role: ExtendedRoleAssignment) => (
        <Box display="flex" flexDirection="column" maxWidth={160}>
          <Typography
            style={{opacity: cloneableRoleIds.includes(role.id) ? 1 : 0.5, textAlign: 'left'}}
          >
            {role.dataScope['businessLine'] ? role.dataScope['businessLine'] : '-'}
          </Typography>
        </Box>
      )
    },
    orgUnitId: {
      field: 'orgUnitId',
      sortable: false,
      filterable: false,
      headerName: t('manageUser.cloneDialog.orgUnitId'),
      headerAlign: 'left',
      renderCell: (role: ExtendedRoleAssignment) => {
        const orgUnitId = role.dataScope?.['orgUnitId'] as string
        const countryId = role.dataScope?.['countryId'] as string
        const businessLine = role.dataScope?.['businessLine'] as string

        const isCloneable = cloneableRoleIds.includes(role.id)

        return (
          <Box display="flex" flexDirection="column" maxWidth={160}>
            <Typography
              style={{opacity: cloneableRoleIds.includes(role.id) ? 1 : 0.5, textAlign: 'left'}}
            >
              {orgUnitId ? (
                <OrgUnit
                  isCloneable={isCloneable}
                  orgUnitId={orgUnitId}
                  countryId={countryId}
                  businessLine={businessLine}
                />
              ) : (
                <Typography style={{textAlign: 'left', opacity: isCloneable ? 1 : 0.5}}>
                  -
                </Typography>
              )}
            </Typography>
          </Box>
        )
      }
    },
    customerId: {
      field: 'customerId',
      headerName: t('manageUser.cloneDialog.customerId'),
      headerAlign: 'left',
      sortable: false,
      filterable: false,
      filterShowSearch: false,
      renderCell: (role: ExtendedRoleAssignment) => {
        const customerIds = role.dataScope?.['customerIds'] as string[]
        return (
          <Box style={{display: 'flex', flexDirection: 'column', maxWidth: '160px', gap: '4px'}}>
            {customerIds.map((id) => (
              <Typography
                key={id}
                style={{opacity: cloneableRoleIds.includes(role.id) ? 1 : 0.5, textAlign: 'left'}}
              >
                {id.split('.')[1]}
              </Typography>
            )) || '-'}
          </Box>
        )
      }
    },
    companyName: {
      field: 'companyName',
      sortable: false,
      filterable: false,
      headerName: t('manageUser.cloneDialog.companyName'),
      headerAlign: 'left',
      renderCell: (role: ExtendedRoleAssignment) => {
        const isCloneable = cloneableRoleIds.includes(role.id)
        return (
          <Box style={{display: 'flex', flexDirection: 'column', gap: '4px'}}>
            {role.customers?.map((customer) => {
              return Object.entries(customer).map(([customerId, customerName]) => {
                return (
                  <CompanyName
                    key={customerId}
                    isCloneable={isCloneable}
                    customerName={customerName}
                  />
                )
              })
            }) || (
              <Typography style={{textAlign: 'left', opacity: isCloneable ? 1 : 0.5}}>-</Typography>
            )}
          </Box>
        )
      }
    }
  }

  const rowOrder = (rowsPool: {[key: string]: TableColumnType}) => [
    rowsPool.role,
    rowsPool.area,
    rowsPool.country,
    rowsPool.businessLine,
    rowsPool.orgUnitId,
    rowsPool.customerId,
    rowsPool.companyName
  ]
  return rowOrder(rowsPool)
}
